<template>
    <section>
        <div class="careers-detail lockout" v-if="jobData && data">
            <div class="careers-detail__right">
                <h1 class="careers-detail__right__title">{{ jobData.position }}</h1>
                <p class="careers-detail__right__type">{{ jobData.type }}</p>
                <h2 class="careers-detail__right__subtitle">{{ jobData.subtitle}}</h2>
                <div class="description">

                    <div class="description__copy" v-html="jobData.details"></div>

                    <div v-for="(block, i) in jobData.content" :id="block.id">

                        <!-- copy -->
                        <div class="description__copy-block" v-html="block.content" v-if="block.type == 'copy'"></div>

                        <!-- Image -->
                        <div class="description__image-block" v-if="block.type == 'image'" :class="{ center : block.assets[0].alignment == 'center'}">
                            <router-link :to="{ name: 'careersDetailsMediaModal', params: { mediaId: block.assets[0].id } }" append @click.native="modalAssets=block.assets">
                                <img :src="block.assets[0].thumb" :alt="block.assets[0].caption.text" :class="{ center : block.assets[0].alignment == 'center'}">
                            </router-link>
                            <div class="caption" v-html="block.caption"></div>
                        </div>

                        <!-- Text Wrap Image -->
                        <div :class="`description__text-wrap-img ${block.imagePosition}`" v-if="block.type == 'textWrapImage'">
                            <img :src="block.imagePath">
                            <div class="copy" v-html="block.copy"></div>
                        </div>

                        <!-- Video -->
                        <div class="description__video-block" v-if="block.type == 'video'">
                            <router-link :to="{ name: 'careersDetailsMediaModal', params: { mediaId: block.assets[0].id } }" append @click.native="modalAssets=block.assets">
                                <div class="description__video-block__wrapper">
                                    <div class="thumb" :style="{ 'background-image': 'url(' + block.path + ')' }"></div>
                                    <div class="overlay">
                                        <img src="../assets/home/scene1/icon-play.svg">
                                        <h4 v-html="block.caption"></h4>
                                    </div>
                                </div>
                                <!-- <p class="caption">{{ block.caption }}</p> -->
                            </router-link>
                        </div>

                    </div>

                </div>
                <div class="rise">
                    <h2>Our Journey, Your Story</h2>
                    <a :href="jobData.link" target="_blank">
                        <div class="btn btn--red-outline">
                            <span>APPLY TODAY</span>
                        </div>
                    </a>
                </div>
            </div>

            <div class="careers-detail__left">
                <!-- <div id="slider"></div> -->
                <div class="images">
                    <div class="images__top">

                        <svg role = "img" aria-label = "A decorative frame border." class="careerDetailSvg" enable-background="new 0 0 573.53 1147.06" viewBox="0 0 573.53 1147.06" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <clipPath id="a">
                                <path d="m544.81 881.87v118.68c0 32.1-12.98 61.08-34.02 82.12-21.05 21.03-50.02 34.01-82.12 34.02h-183.75v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02z" />
                            </clipPath>
                            <clipPath id="b">
                                <path d="m479.8 24.58c-54.35 0-106.48 9.53-154.8 27.01-175.09 63.34-300.2 231.05-300.2 427.99h455z" />
                            </clipPath>
                            <g clip-path="url(#a)">
                                <image class="image-bottom" height="800" overflow="visible" transform="matrix(.2935 0 0 .2935 245 881.2)" width="800" :xlink:href="data.positions[$route.params.category].mondrianImage2.src"><desc>{{ data.positions[$route.params.category].mondrianImage2.alt }}</desc></image>
                            </g>
                            <g clip-path="url(#b)">
                                <image class="image-top" height="800" overflow="visible" transform="matrix(.568 0 0 .568 24.7955 24.5784)" width="800" :xlink:href="data.positions[$route.params.category].mondrianImage1.src"><desc>{{ data.positions[$route.params.category].mondrianImage1.alt }}</desc></image>
                            </g>

                            <!-- red -->
                            <path class="bottom-bottom" d="m364.55 1116.69h-119.63" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                            <path class="bottom-left" d="m244.92 1116.69v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02h118.64" style="fill:none;stroke:#b22028;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                            <path class="bottom-mask" d="m479.23 1000.55v-118.68h65.58v118.68c0 64.14-52 116.14-116.14 116.14h-65.8s109.36 2.54 116.36-116.14z" fill="#dfd9c8" />
                            <g fill="none">
                                <path class="bottom-right" d="m544.81 881.87v118.68c0 64.14-52 116.14-116.14 116.14h-65.8" stroke="#b22028" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                                <path class="bottom-horizontal" d="m479.23 881.87h65.58" stroke="#b22028" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                                <path class="middle-line" d="m363.45 1116.69s109.36 2.54 116.35-116.14v-521.97" stroke="#b22028" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                            </g>
                            <!-- <path class="top-mask" d="m479.63 479.27v-131.18c-131.17 0-129.47 131.17-129.47 131.17h129.47" fill="#fff" /> -->
                            <g stroke="#b22028" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">
                                <path class="top-arc-small" d="m479.63 348.09c-131.17 0-129.47 131.17-129.47 131.17" fill="#fff" />
                                <g fill="none">
                                    <path class="top-arc-large" d="m479.8 24.58c-54.35 0-106.48 9.53-154.8 27.01-175.09 63.34-300.2 231.05-300.2 427.99" />
                                    <path class="top-horizontal" d="m24.8 479.58h455" />
                                    <path class="top-vertical" d="m479.8 479.58v-455" />
                                </g>
                            </g>

                            <!-- tan -->
                            <path class="bottom-bottom" d="m364.55 1116.69h-119.63" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                            <path class="bottom-left" d="m244.92 1116.69v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02h118.64" style="fill:none;stroke:#d6d0b4;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                            <path class="bottom-mask" d="m479.23 1000.55v-118.68h65.58v118.68c0 64.14-52 116.14-116.14 116.14h-65.8s109.36 2.54 116.36-116.14z" fill="#dfd9c8" />
                            <g fill="none">
                                <path class="bottom-right" d="m544.81 881.87v118.68c0 64.14-52 116.14-116.14 116.14h-65.8" stroke="#d6d0b4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                                <path class="bottom-horizontal" d="m479.23 881.87h65.58" stroke="#d6d0b4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                                <path class="middle-line" d="m363.45 1116.69s109.36 2.54 116.35-116.14v-521.97" stroke="#d6d0b4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                            </g>
                            <!-- <path class="top-mask" d="m479.63 479.27v-131.18c-131.17 0-129.47 131.17-129.47 131.17h129.47" fill="#fff" /> -->
                            <g stroke="#d6d0b4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">
                                <path class="top-arc-small" d="m479.63 348.09c-131.17 0-129.47 131.17-129.47 131.17" fill="#fff" />
                                <g fill="none">
                                    <path class="top-arc-large" d="m479.8 24.58c-54.35 0-106.48 9.53-154.8 27.01-175.09 63.34-300.2 231.05-300.2 427.99" />
                                    <path class="top-horizontal" d="m24.8 479.58h455" />
                                    <path class="top-vertical" d="m479.8 479.58v-455" />
                                </g>
                            </g>

                            <!-- black -->
                            <path class="bottom-bottom" d="m364.55 1116.69h-119.63" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                            <path class="bottom-left" d="m244.92 1116.69v-118.68c0-32.1 12.98-61.07 34.02-82.12 21.05-21.03 50.02-34.01 82.12-34.02h118.64" style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" />
                            <path class="bottom-mask" d="m479.23 1000.55v-118.68h65.58v118.68c0 64.14-52 116.14-116.14 116.14h-65.8s109.36 2.54 116.36-116.14z" fill="#dfd9c8" />
                            <g fill="none">
                                <path class="bottom-right" d="m544.81 881.87v118.68c0 64.14-52 116.14-116.14 116.14h-65.8" stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                                <path class="bottom-horizontal" d="m479.23 881.87h65.58" stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                                <path class="middle-line" d="m363.45 1116.69s109.36 2.54 116.35-116.14v-521.97" stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4" />
                            </g>
                            <path class="top-mask" d="m479.63 479.27v-131.18c-131.17 0-129.47 131.17-129.47 131.17h129.47" fill="#fff" />
                            <g stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4">
                                <path class="top-arc-small" d="m479.63 348.09c-131.17 0-129.47 131.17-129.47 131.17" fill="#fff" />
                                <g fill="none">
                                    <path class="top-arc-large" d="m479.8 24.58c-54.35 0-106.48 9.53-154.8 27.01-175.09 63.34-300.2 231.05-300.2 427.99" />
                                    <path class="top-horizontal" d="m24.8 479.58h455" />
                                    <path class="top-vertical" d="m479.8 479.58v-455" />
                                </g>
                            </g>



                        </svg>



                    </div>
                    <!-- <div class="careers-detail__right__subtitle">Are you the mix of artistic and math wiz we need?</div> -->
                    <div class="images__subtitle">{{ jobData.subtitle }}</div>
                    <div class="images__bottom"></div>
                </div>
                <div class="other-positions" v-if="jobData.relatedCareers.length > 0">
                    <h3>Other Similar Positions</h3>

                    <div v-for="(related, i) in jobData.relatedCareers" :key="related.id" class="position">
                        <a :href="related.slug">
                            <h4 class="title">{{ related.position }}</h4>
                            <p class="type">{{ related.type }}</p>
                        </a>
                    </div>

                    <!-- <router-link to="/careers"> -->
                    <router-link :to="`/careers/${jobData.category}`">
                        <diamond-button>MORE</diamond-button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="preload-height" v-else></div>

        <transition name="fade">
            <router-view parentName="careers-detail" :data="modalAssets" v-if="modalAssets" />
        </transition>

    </section>
</template>

<script>
    import Vue from 'vue'
    import router from '../router'
    import DiamondButton from '../components/buttons/DiamondButton'
    import axios from 'axios'

    import CareersDetailMixin from '../mixins/CareersDetailTimeline.js'
    import Meta from '../mixins/Meta.js'
    import 'jquery-ui/ui/widgets/slider.js';

    export default {
        props: ['data'],
        mixins: [CareersDetailMixin, Meta],
        components: {
            DiamondButton
        },
        data() {
            return {
                jobData: null,
                modalAssets: null
            }
        },
        mounted() {
            $(window).scrollTop(0);
            this.loadData();
        },
        methods: {
            loadData() {
                axios.get(this.$json('/data/careers/' + this.$route.params.jobId + '.json'))
                    .then(response => {
                        this.jobData = response.data;
                        this.setMetaData(this.jobData.meta);
                        // console.log(this.jobData);
                        this.setModalAssetsIfDeeplinking();
                        this.initMondrian();

                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            initMondrian() {
                Vue.nextTick(() => {
                    this.initTimeline();
                    this.initSlider();
                    setTimeout(() => {
                        // in mixin
                        this.tl.play();
                    }, 600);
                });
            },
            setModalAssetsIfDeeplinking() {
                // if showing a modal on a hard-refresh, need to set this.modalAssets data manually
                if (this.$route.params.mediaId) {
                    for (var i = 0; i < this.jobData.content.length; i++) {
                        var curContentItem = this.jobData.content[i];
                        if (curContentItem.assets) {
                            for (var j = 0; j < curContentItem.assets.length; j++) {
                                var curAsset = curContentItem.assets[j];
                                if (curAsset.id == this.$route.params.mediaId) {
                                    this.modalAssets = curContentItem.assets;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        },
        watch: {
            data() {
                // this.countCareers();
                this.initMondrian();
            },
            // '$route'(to, from) {
            //     if (from.name == 'careersDetailsMediaModal') {
            //         this.momodalAssets = null;
            //     }
            // }
        }
    }
</script>